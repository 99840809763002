import React from "react"
import { Link } from "gatsby"

import navigation from "../content/navigation"

const FooterNavLink = ({ to, children }) => (
  <div className="mb-2">
    <Link to={to} className="has-text-white is-size-7 op-9">
      {children}
    </Link>
  </div>
)

const FooterNavColumn = ({ title, children }) => (
  <div>
    <h4 className="has-text-white has-text-weight-semibold mb-4">{title}</h4>
    <nav>{children}</nav>
  </div>
)

const TwitterLogo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      style={{ fill: "currentcolor" }}
      d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"
    />
  </svg>
)

const InstagramLogo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      style={{ fill: "currentcolor" }}
      d="M7.03.084c-1.277.06-2.149.264-2.91.563a5.874 5.874 0 0 0-2.124 1.388 5.878 5.878 0 0 0-1.38 2.127C.321 4.926.12 5.8.064 7.076.008 8.354-.005 8.764.001 12.023c.007 3.259.021 3.667.083 4.947.061 1.277.264 2.149.563 2.911.308.789.72 1.457 1.388 2.123a5.872 5.872 0 0 0 2.129 1.38c.763.295 1.636.496 2.913.552 1.278.056 1.689.069 4.947.063 3.257-.007 3.668-.021 4.947-.082 1.28-.06 2.147-.265 2.91-.563a5.881 5.881 0 0 0 2.123-1.388 5.881 5.881 0 0 0 1.38-2.129c.295-.763.496-1.636.551-2.912.056-1.28.07-1.69.063-4.948-.006-3.258-.02-3.667-.081-4.947-.06-1.28-.264-2.148-.564-2.911a5.892 5.892 0 0 0-1.387-2.123 5.857 5.857 0 0 0-2.128-1.38C19.074.322 18.202.12 16.924.066 15.647.009 15.236-.006 11.977 0 8.718.008 8.31.021 7.03.084m.14 21.693c-1.17-.05-1.805-.245-2.228-.408a3.736 3.736 0 0 1-1.382-.895 3.695 3.695 0 0 1-.9-1.378c-.165-.423-.363-1.058-.417-2.228-.06-1.264-.072-1.644-.08-4.848-.006-3.204.006-3.583.061-4.848.05-1.169.246-1.805.408-2.228.216-.561.477-.96.895-1.382a3.705 3.705 0 0 1 1.379-.9c.423-.165 1.057-.361 2.227-.417 1.265-.06 1.644-.072 4.848-.08 3.203-.006 3.583.006 4.85.062 1.168.05 1.804.244 2.227.408.56.216.96.475 1.382.895.421.42.681.817.9 1.378.165.422.362 1.056.417 2.227.06 1.265.074 1.645.08 4.848.005 3.203-.006 3.583-.061 4.848-.051 1.17-.245 1.805-.408 2.23-.216.56-.477.96-.896 1.38a3.705 3.705 0 0 1-1.378.9c-.422.165-1.058.362-2.226.418-1.266.06-1.645.072-4.85.079-3.204.007-3.582-.006-4.848-.06m9.783-16.192a1.44 1.44 0 1 0 1.437-1.442 1.44 1.44 0 0 0-1.437 1.442M5.839 12.012a6.161 6.161 0 1 0 12.323-.024 6.162 6.162 0 0 0-12.323.024M8 12.008A4 4 0 1 1 12.008 16 4 4 0 0 1 8 12.008"
    />
  </svg>
)

const LinkedinLogo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      style={{ fill: "currentcolor" }}
      d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 0 1-2.063-2.065 2.064 2.064 0 1 1 2.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"
    />
  </svg>
)

const logoScaleFactor = 0.5

export default ({ showNavigation = true }) => (
  <footer className="has-background-dark">
    <div className="section container">
      <div className="description">
        <Link
          to="/"
          className="logo"
          style={{
            height: logoScaleFactor * 61,
            width: logoScaleFactor * 300,
          }}
        ></Link>
        <p className="has-text-white mt-4 op-5 is-size-7">
          inPowered AI enables agencies and brands to maximize their media
          outcomes. The company’s AI optimization can be activated
          programmatically via PMP or integrate directly into DSPs to increase
          campaign performance by 50% - 300%. Whether the goal is to drive
          traffic, brand lift, viewability, leads, conversions, or ROAS,
          inPowered AI’s optimization algorithms predict which impressions will
          result in the desired outcome. inPowered AI was founded in 2014 by
          Peyman and Pirouz Nilforoush after their previous company, NetShelter,
          was acquired by Ziff Davis.
        </p>
      </div>
      {showNavigation && (
        <div className="nav">
          <FooterNavColumn title="Company">
            {navigation.map(({ to, text }) => (
              <FooterNavLink to={to} key={to}>
                {text}
              </FooterNavLink>
            ))}
          </FooterNavColumn>
        </div>
      )}

      <div class="social is-flex is-flex-gap-6 has-text-light">
        <Link
          to="https://www.linkedin.com/company/inpoweredai"
          target="_blank"
          className="has-text-light"
        >
          <LinkedinLogo style={{ width: 16, height: 16 }}></LinkedinLogo>
        </Link>
        <Link
          to="https://www.instagram.com/inpoweredai"
          target="_blank"
          className="has-text-light"
        >
          <InstagramLogo style={{ width: 16, height: 16 }}></InstagramLogo>
        </Link>
        <Link
          to="https://twitter.com/inpoweredai"
          target="_blank"
          className="has-text-light"
        >
          <TwitterLogo style={{ width: 16, height: 16 }}></TwitterLogo>
        </Link>
      </div>
      <div class="copyright is-flex is-flex-gap-3 is-size-7">
        <div class="has-text-light">
          © Copyright {new Date().getFullYear()} inPowered
        </div>
        <Link class="has-text-light is-underlined" to="/privacy-policy">
          Privacy Policy
        </Link>
        <div class="has-text-light">|</div>
        <Link class="has-text-light is-underlined" to="/terms-and-conditions">
          Terms and Conditions
        </Link>
      </div>
    </div>
  </footer>
)
